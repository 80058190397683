
/* Définition des variables typographiques */
:root {
  --e-global-typography-primary-font-family: 'Hedvig', sans-serif;
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: 'Hedvig', sans-serif;
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: 'Josefin Sans', sans-serif;
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: 'Josefin Sans', sans-serif;
  --e-global-typography-accent-font-weight: normal;
  --e-global-color-secondary: #333;
}

.carousel-control-prev,
.carousel-control-next {
  width: 50px; /* Largeur des flèches */
  opacity: 0.7; /* Opacité des flèches, ajustez selon vos besoins */
  margin: 5rem;
  padding: 0 10px; /* Ajoutez un padding interne pour les éloigner du bord */
}

.carousel-control-prev {
  left: 10px; /* Positionner le contrôle précédent à gauche avec un espace de 10px */
}

.carousel-control-next {
  right: 10px; /* Positionner le contrôle suivant à droite avec un espace de 10px */
}


/* Importation des polices */
@import url('https://fonts.googleapis.com/css2?family=Hedvig:wght@400;600&family=Josefin+Sans:wght@400&display=swap');

/* Styles globaux */
body {
  font-family: var(--e-global-typography-text-font-family);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: 1.6;
  color: var(--e-global-color-secondary);
  background-color: #fbf6f0; /* Couleur de fond du site */
  margin: 0;
  padding-top: 80px;
  text-align: justify;
}


a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  color: #0056b3;
}

/* En-tête */
header {
  background-color: #fff;
  padding: 1rem 0;
  border-bottom: 1px solid #eaeaea;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .logo img {
  max-height: 50px;
}

header nav a {
  margin: 0 1rem;
  color: var(--e-global-color-secondary);
}

/* Section d'équipe */
.team-section {
  padding: 4rem 2rem;
  text-align: center;
}

p, ul, li{
  font-size: 1.25rem;
}

.team-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family);
  font-weight: var(--e-global-typography-primary-font-weight);
}

.team-section .team-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.team-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  text-align: center;
  padding: 1rem;
}

.team-card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eaeaea;
}

.team-card h3 {
  font-size: 1.5rem;
  margin: 1rem 0;
  font-family: var(--e-global-typography-primary-font-family);
  font-weight: var(--e-global-typography-primary-font-weight);
}

.team-card p {
  color: #666;
  font-family: var(--e-global-typography-text-font-family);
  font-weight: var(--e-global-typography-text-font-weight);
}

/* Boutons */
button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Footer */
footer {
  background-color: #343a40;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

footer p {
  margin: 0;
}

footer a {
  color: #007bff;
}

footer a:hover {
  color: #0056b3;
}

/* Mise en Page Responsive */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
  }

  .team-cards {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem !important; /* Réduire la taille du titre sur petits écrans */
  }

  .text-custom-subtitle {
    font-size: 1.2rem !important; /* Réduire la taille du sous-titre sur petits écrans */
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.5rem !important; /* Encore plus petit sur les très petits écrans */
  }

  .text-custom-subtitle {
    font-size: 1rem !important; /* Encore plus petit sur les très petits écrans */
  }
}

/* Images et Médias */
img {
  max-width: 100%;
  height: auto;
  display: block;
}
